@import '../../style/style.sass'
    
.header1
    padding: 8px 89px 8px 80px
    display: flex
    justify-content: space-between
    align-items: center
    background-color: #F5F8FF
    height: 40px
    &__nav
        display: flex
        flex-direction: row
        list-style-type: none
        margin-bottom: 0
        padding-left: 0
        &_item
            margin-right: 40px
            font-size: 14px
            font-family: Rubik
            a
                text-decoration: none
                color: #282828
                &:hover
                    color: $blue-color
    &__social_icon:hover
        svg path
            fill: $blue-color

@media(max-width: 768px)
    .header1
        padding: 8px 50px

@media(max-width: 640px)
    .header1
        padding: 8px 30px
        &__nav_item
            margin-right: 25px

@media(max-width: 530px)
    .header1
        padding: 8px 20px
        &__nav_item
            margin-right: 15px
            font-size: 13px

@media(max-width: 428px)
    .header1
        display: none