@import '../../../style/style'
    
.product
    width: 276px //275px
    height: 662px
    border-radius: 40px
    position: relative
    margin-bottom: 5px
    &__img
        width: 100%
        height: 368px //402px
        object-fit: cover
        border-radius: 40px
    &__title
        padding: 12px
        font-size: 18px
        line-height: 24px
    &__price
        display: none
    &__info
        font-size: 16px
        line-height: 24px
        letter-spacing: 0.1px
        padding-left: 12px
        padding-right: 12px
        margin-bottom: 12px
        &_list
            display: flex
            flex-wrap: wrap
            &-item
                max-width: 68px
                height: 27px
                line-height: 19px
                color: rgb(82, 82, 82)
                background: rgb(238, 238, 238)
                border: 1px solid rgb(238, 238, 238)
                border-radius: 39px
                margin-right: 6px
                margin-bottom: 6px
                padding: 4px 6px
                cursor: pointer
                &:hover
                    background: rgb(245, 248, 255)
                    border: 1px solid rgb(42, 92, 246)
                    color: rgb(42, 92, 246)
    &__cost
        display: flex
        justify-content: space-between
        align-items: center
        padding: 0 12px
        &_price
            font-size: 24px
            font-weight: bold
            line-height: 28px
        &_btn
            height: 39px
            padding: 11px 24px
            border-radius: 61px
            @include btn
            img
                margin-right: 9px
            span
                color: #FFFFFF
    svg
        position: absolute
        top: 20px
        right: 10px
        &:hover
            path
                fill: $blue-color
    &:hover
        background: rgb(255, 255, 255)
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)

@media(max-width: 570px)
    .product
        width: 210px
        height: unset
        &__img
            height: 280px
        &__title
            font-size: 14px
            font-weight: 500
        &__price
            display: block
            padding: 0 12px 8px
            font-size: 18px
            font-weight: bold
            line-height: 22px
        &__info
            font-size: 14px
            &_list-item
                max-width: 55px
                height: 20px
                font-size: 12px
                line-height: 12px
        &__cost
            display: block
            padding-bottom: 10px
            &_price
                display: none

@media(max-width: 428px)
    .product
        width: 180px
        &__img
            height: 240px
        &__title
            font-size: 12px
        &__price
            font-size: 16px
        &__info
            font-size: 14px
            &_list-item
                max-width: 47px
                height: 18px
                font-size: 10px
                line-height: 10px
        &__cost_btn
            width: 148px
            height: 38px
            span
                display: none