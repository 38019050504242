@import '../../../style/style'
    
.banner
    width: 87%
    height: 356px
    margin: 0 auto
    border-radius: 12px
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2)
    img
        height: 356px
        object-fit: cover
        border-radius: 12px
    &__title
        color: rgb(92, 113, 178)
        font-size: 28px
        font-weight: 600
        line-height: 33px
        letter-spacing: 0.1px
        text-align: left
    &__list
        padding-top: 16px
        &_item
            font-size: 16px
            font-weight: 400
            text-align: left
    &__btn
        width: 206px
        height: 42px
        padding: 11px 24px
        border-radius: 61px
        @include btn

@media (max-width: 768px)
    .banner
        &__title
            font-size: 24px
            line-height: 26px

@media (max-width: 428px)
    .banner
        height: 176px
        img
            height: 176px
        &__title
            font-size: 16px
            line-height: 18px
        &__list
            padding: 6px 0 0 14px
            margin-bottom: 5px
            &_item
                font-size: 10px
                line-height: 13px
        &__btn
            width: fit-content
            height: 21px
            padding: 6px 12px
            font-size: 8px