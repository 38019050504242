@import '../../style/style.sass'
    
.header2
    padding: 14px 80px
    display: flex
    justify-content: space-between
    align-items: center
    height: 68px
    &__btn
        display: flex
        width: 176px
        padding: 10px 24px 10px 16px
        align-items: center
        gap: 12px
        flex-shrink: 0
        border-radius: 60px
        @include btn
        span
            color: #FFFFFF
    &__search
        position: relative
        margin-left: 24px
        margin-right: 24px
        width: 100%
        top: -18px
        &_input
            width: 90%
            border-radius: 58px
            border: none
            padding: 9px 22px 8px 24px
            font-size: 16px
            letter-spacing: 0.1px
            z-index: 2
            position: absolute
            top: 2px
            left: 2px
        &_btn
            width: 100%
            height: 39px
            border-radius: 56px
            text-align: right
            padding-right: 16px
            position: absolute
            @include btn
    &__profile
        display: flex
        justify-content: space-between
        align-items: center
        width: 228px
        &_item
            width: 76px
            display: flex
            flex-direction: column
            align-items: center
            text-decoration: none
            &:hover
                .header2__profile_item_text
                    color: $blue-color
                svg path
                    stroke: $blue-color

@media(max-width: 1260px)
    .header2__search
        &_input
            width: 85%

@media(max-width: 1100px)
    .header2__search
        &_input
            width: 80%

@media(max-width: 991px)
    .header2
        padding: 14px 60px
        &__search
            &_input
                width: 75%
                font-size: 14px
                letter-spacing: 0
            &_btn
                font-size: 14px
                height: 36px

@media(max-width: 863px)
    .header2
        padding: 14px 40px
        &__search
            &_input
                padding: 8px 15px

@media(max-width: 800px)
    .header2
        padding: 14px 30px
        &__btn
            width: 140px
        &__search
            margin-left: 20px
            margin-right: 20px
            &_input
                width: 80%
        &__profile
            width: 120px
            &_item
                width: 40px
                &_text
                    display: none

@media(max-width: 676px)
    .header2
        display: grid
        grid-template-columns: repeat(2, 50%)
        grid-template-rows: repeat(2, 40px)
        column-gap: 0
        row-gap: 12px
        height: 113px
        &__search
            grid-row: 2
            grid-column: 1 / 3
            margin: 0
            &_input
                width: 83%
        &__profile
            justify-self: end

@media(max-width: 428px)
    .header2
        height: 84px
        padding: 12px 24px 8px
        grid-template-rows: 28px 24px
        &__btn
            background: none
            box-shadow: none
            width: 24px
            padding: 0
            span
                display: none
            svg
                width: 24px
                height: 24px
                path
                    fill: #282828
            &:hover
                background: none
        &__search
            &_input
                height: 20px
                font-size: 10px
                letter-spacing: 0.2px
            &_btn
                height: 24px
                font-size: 10px
                letter-spacing: 0.2px

@media(max-width: 375px)
    .header2__search
        &_input
            width: 80%