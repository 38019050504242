.pageup
    width: 62px
    height: 62px
    position: fixed
    bottom: 96px
    right: 81px
    z-index: 100
    &:hover
        svg 
            path
                fill: rgb(31, 108, 190)
                opasity: unset
            rect
                opacity: unset