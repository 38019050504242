@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css')
@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css')

@import './custom'
@import './mixins'


$blue-color: #2A5CF6
$darkgrey-color: #525252
$grey-color: #A9A9A9
$lightgrey-color: #EEEEEE
$white-color: #FFFFFF
$black-color: #282828

*
    box-sizing: border-box
    margin: 0 
    padding: 0
    font-family: 'Rubik'
    color: rgb(40, 40, 40)

#root
    display: flex
    min-height: 100vh
    flex-direction: column

body
    overflow-y: scroll