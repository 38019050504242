.advantage
    width: 237px
    height: 262px
    position: relative
    background: linear-gradient(-33.54deg, rgb(88, 119, 254) -3.965%,rgba(255, 255, 255, 0) 74.867%,rgba(255, 255, 255, 0) 74.867%),linear-gradient(35.13deg, rgb(194, 255, 248) -1.372%,rgb(255, 255, 255) 70.578%)
    border-radius: 40px
    padding: 24px 24px 0
    &__title
        color: rgb(40, 40, 40)
        font-family: Rubik
        font-size: 16px
        font-weight: 500
        line-height: 19px
        text-align-last: left
        letter-spacing: 0.1px
    &__description
        color: rgb(82, 82, 82)
        font-family: Rubik
        font-size: 14px
        font-weight: 400
        line-height: 17px
        text-align-last: left
        opacity: 0.6
        letter-spacing: 0.1px
        margin-top: 8px
    &__img
        position: absolute
        bottom: -18px
        left: -20px
        width: 100%
        height: 119px
        object-fit: contain

@media(max-width: 570px)
    .advantage
        width: 185px
        height: 235px
        padding: 18px 18px 0
        &__title
            font-size: 14px
        &__description
            font-size: 12px
        &__img
            bottom: -14px
            left: 0
            width: 90%
            height: 90px

@media(max-width: 428px)
    .advantage
        width: 154px
        height: 203px
        padding: 16px 16px 0
        &__title
            font-size: 12px
        &__description
            font-size: 10px
            margin-top: 5px