.footer
    background-color: rgb(238, 238, 238)
    &__info
        padding: 40px 80px
        display: grid
        grid-template-columns: repeat(4, 1fr)
        font-size: 18px
        font-weight: 400
        line-height: 25px
        &_nav
            list-style-type: none
            padding: 0
            padding-right: 15px
            &_item
                a
                    font-size: 14px
                    line-height: 17px
                    color: rgb(40, 40, 40)
                    opacity: 0.6
                    text-decoration: none
        &_contacts
            &_subscribe
                position: relative
            &_icons
                display: flex
                flex-direction: column
                align-items: start
                margin-bottom: 12px
            &_title
                opacity: 0.7
                font-size: 16px
                line-height: 24px
            input
                width: 100%
                height: 50px
                margin: 12px 0
                padding: 16px
                font-size: 14px
                line-height: 17px
                border-radius: 25px
                border: none
                opacity: 0.4
                &:hover
                    opacity: 1
                    color: rgb(40, 40, 40)
                    border: 1px solid rgb(42, 92, 246)
            button
                border-radius: 50%
                border: none
                position: absolute
                top: 13px
                right: 3px
                svg
                    width: 48px
                    height: 48px
                &:hover
                    svg path
                        fill: rgb(31, 108, 190)
            p
                color: rgb(128, 128, 128)
                font-size: 10px
                line-height: 12px
            a
                margin-right: 8px
                &:hover
                    svg path
                        fill: rgb(31, 108, 190)
    &__rest
        height: 48px
        display: flex
        justify-content: space-between
        align-items: center
        padding: 12px 80px
        &_year
            color: rgb(82, 82, 82)
            font-size: 14px
            font-weight: 400
            line-height: 17px
        &_logos
            height: 24px
    hr
        margin: 0

@media (max-width: 1100px)
    .footer
        &__info
            grid-template-columns: repeat(2, 1fr) 120px 1fr
            &_contacts
                input
                    padding: 12px
                    font-size: 14px
                    line-height: 16px

@media (max-width: 991px)
    .footer
        &__info
            padding: 40px 60px
            grid-template-columns: repeat(2, 1fr) 60px 1fr
            &_contacts
                input
                    font-size: 12px
                    line-height: 14px

@media (max-width: 768px)
    .footer
        &__info
            padding: 30px 30px
            grid-template-columns: repeat(3, 1fr)
            &_empty
                display: none

@media (max-width: 668px)
    .footer
        &__info
            grid-template-columns: unset
            grid-template-rows: auto auto
            &_contacts
                grid-row: 1
                margin-bottom: 16px
                input
                    padding: 16px
                    font-size: 14px
                    line-height: 17px
        &__rest
            display: flex
            flex-direction: column
            align-items: start
            padding: 0 30px 16px
            height: 70px
            &_year
                padding-top: 16px
                order: 2
            &_logos
                order: 1
    hr
        display: none
