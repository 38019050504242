.scrollableContainer 
    position: relative
    margin: 0 auto
    &__list
        display: flex
        overflow-x: hidden
        overflow-y: hidden
        list-style: none
        padding: 0 10px
        margin: 0
        width: 92vw
        &_item
            &:not(:last-child) 
                margin-right: 2em

.button 
    position: absolute
    top: 120px
    border-radius: 50%
    border: none
    cursor: pointer
    z-index: 2
    opacity: 0.4
    &--hidden
        display: none  
    &:hover
        opacity: 1
.buttonLeft 
    left: -20px
    // rotate: 180deg
    transform: rotate(180deg)
    /* Firefox */
    -moz-transform: rotate(180deg)
    /* Safari and Chrome */
    -webkit-transform: rotate(180deg)
    /* Opera */
    -o-transform: rotate(180deg)
.buttonRight 
    right: -20px

@media(max-width: 768px)
    .scrollableContainer__list
        width: 90vw

@media(max-width: 570px)
    .scrollableContainer__list
        width: 85vw
        &_item
            &:not(:last-child) 
                margin-right: 1em
    .button
        top: 100px

@media(max-width: 428px)
    .scrollableContainer__list
        width: 81vw