.carousel
    &-caption
        width: 477px
        display: flex
        flex-direction: column
        top: 40px
        left: 170px
    &-indicators
        bottom: -54px
        [data-bs-target]
            width: 12px
            height: 12px
            border-radius: 50%
            background-color: rgba(40, 40, 40, 0.5)
            opacity: 0.5
            &.active
                background-color: rgb(42, 92, 246)

.accordion
    &-item
        border: none
    &-button 
        background-color: rgb(238, 238, 238)
        padding: 8px 20px 8px 0
        font-size: 18px
        font-weight: 400
        line-height: 25px
        &.collapsed, &:not(.collapsed)
            background-color: rgb(238, 238, 238)
            color: rgb(33, 37, 41)
            box-shadow: unset
    &-body
        background-color: rgb(238, 238, 238)
        padding: 0 12px

@media (max-width: 768px)
    .carousel
        &-caption
            top: 30px
            left: 100px
            width: 400px

@media (max-width: 670px)
    .carousel
        &-caption
            top: 20px
            left: 60px
            width: 350px

@media (max-width: 576px)
    .carousel
        &-caption
            top: 10px
            left: 27px
            width: 267px

@media (max-width: 428px)
    .carousel
        &-caption
            top: 0
        &-indicators
            bottom: -20px
            [data-bs-target]
                width: 8px
                height: 8px